import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Contactus from "../components/Contactus";
import Floatingcall from "../components/floatingbutton/Floatingcall";
import { Helmet } from "react-helmet";

function HousekeepingManpower(){

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

    return(
        <Fragment>
          <Helmet>
            <title> House Keeping Services In Noida | Professional House Keeping Services </title>
            <meta name="tttle" content="House Keeping Services In Noida | Professional House Keeping Services" />
            <meta name="description" content="Looking for top-notch housekeeping services in Noida? Discover professional housekeeping services tailored to meet your needs." />
            <meta name="keywords" content=" Housekeeping services, Personal protection officer, Private security officer, Private security agents, Armed security services, Private Security Guard, Personal security guard, Private security personnel, Personal security officer, Private security service" />
          </Helmet>
            
            <Floatingcall />
         
            <section id="about" class="about">
            <div class="container" data-aos="fade-up">

            <div class="section-header">
            <h2>Housekeeping Manpower Services</h2>
            <p>HelperJi: Expert Domestic Staffing Services </p>
             </div>

           <div class="row gy-4">
           <div class="col-lg-6">
            <img src="/assets/img/housekeeping1.png" class="img-fluid rounded-4 mb-4" alt="" />
            
            </div>
          
          <div class="col-lg-6">
            <div class="content ps-0 ps-lg-5">
              <h3 class="fst-italic">
              Among the housekeeping manpower services we offer are:
              </h3>
              <ul>
                <li><b>Floor Cleaning and Maintenance:</b> Whether your floors are hardwood, tiled, or carpeted, we guarantee that they are always immaculate and attractive.</li>
                <li><b>Dusting and Sanitizing:</b> To maintain a hygienic atmosphere, our staff carefully dusts and sanitises all surfaces, including furniture, electronics, and communal areas.                </li>
                <li><b>Bathroom and Restroom Cleaning: </b>We keep restrooms sanitised, clean, and restocked with supplies that are always presentable and fresh.</li>
                <li><b>Pantry and Kitchen Area Cleaning:</b> Our crew takes care of every little thing, from countertops to appliances, to keep your pantry and kitchen areas sanitary and spotless.</li>
                <li><b>Garbage Management: </b>Our housekeeping crew makes sure that garbage is disposed of effectively, that the area is kept clean, and that environmental regulations are followed.</li>
                <li><b>Furniture & Upholstery Care:</b> To keep couches, chairs, and other upholstery in top shape, we provide expert cleaning.</li>
                <li><b>Glass and window cleaning</b> is handled by our staff, who will leave your windows and glass surfaces immaculate after cleaning both inside and outside.</li>
                <li><b>Specialised Cleaning Tasks:</b> Our housekeeping services take care of every detail, from conference rooms to high-touch locations like light switches and door handles.</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section id="services" class="services sections-bg">
          <div class="container" data-aos="fade-up">
    
            <div class="row gy-4">
              
              <div class="col-lg-6">
                <div class="content ps-0 ps-lg-5">
                  <h2>
                  Why Opt for Housekeeping Services from Helper Ji?: 
                  </h2>
                  <p>Helper Ji is proud to provide premium housekeeping staffing services that are customised to your needs. In order to guarantee a clean and well-maintained place, we offer professional and educated housekeeping workers for any type of setting—corporate, residential, or hospitality. Being a top provider of housekeeping services, we stand out due to our dedication to excellence and meticulousness.</p>
                <ul>
                  <li><b>Skilled Professionals:</b> To deliver excellent services while upholding safety and hygiene regulations, our housekeeping staff goes through extensive training.</li>

                  <li><b>Customised Services:</b> To ensure optimal effectiveness and satisfaction, we customise our housekeeping services to match the unique requirements of your establishment.</li>
                  
                  <li><b>Cost-effective and Dependable:</b> We provide competitive pricing without sacrificing service quality, so you always get the most out of your investment.</li>
                  
                  <li><b>Compliant & Trusted: </b>Helper Ji is a reliable partner for all of your housekeeping needs since we adhere to all industry and governmental regulations.</li>
                </ul>
                  <div class="text-center mt-3"><Link to='/contact-us' className="booknow" onClick={scrollToTop}>Book Now</Link></div>
                </div>
              </div>
              <div class="col-lg-6">
                <img src="/assets/img/housekeeping4.png" class="img-fluid rounded-4 mb-4" alt="" />
              </div>
            </div>
    
          </div>
        </section>

       <Contactus />
        </Fragment>
    )
}

export default HousekeepingManpower;