import './App.css';
import React from 'react';
import Home from './pages/Home';
import AboutUs from "./pages/AboutUs";
import CommercialCleaningServices from "./pages/CommercialCleaningServices";
import DonateUs from "./pages/DonateUs";
import Whyus from "./pages/Whyus";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Book from "./pages/Book";
import Booknew from './pages/Booknew';
import Blog1 from './blogs/Blog1';
import Blog2 from './blogs/Blog2';
import Blog3 from './blogs/Blog3';
import Layout from './components/Layout';
import ContactUs from './pages/ContactUs';

import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Helper from './pages/Helper';
import PrivateSecurityServices from './pages/PrivateSecurityServices';
import SecurityGuardServices from './pages/SecurityGuardServices';
import HousekeepingManpower from './pages/HousekeepingManpower';
import PantryBoyServices from './pages/PantryBoyServices';

function App() {
  return (
    
      <div className="App">
        <Helmet>
      <title>Professional Deep Cleaning Services in Noida with Helperji</title>
     <meta name='title' content='Professional Deep Cleaning Services in Noida with Helperji' />
    <meta name="description" content="We offer affordable deep cleaning services in Noida, covering bathroom cleaning, toilet cleaning, and washroom cleaning services. Get in touch 24/7 with +91-7800-7800-95." />
     <meta name="keywords" content="Cleaning Services, Best Cleaning Services in Noida, Book Home Cleaning services Online in Noida, Washroom Cleaning Services in Noida, Office Cleaning Services in Noida, Bathroom Cleaning Services in Noida, Cleaning and Sanitization Services in Noida" />
        </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='about-us' element={<AboutUs />} />
          <Route path='commercial-cleaning-services' element={<CommercialCleaningServices />} />
          <Route path='housekeeping-manpower-services' element={<HousekeepingManpower />} />
          <Route path='security-guard-services' element={<SecurityGuardServices />} />
          <Route path='private-security-services' element={<PrivateSecurityServices />} />
          <Route path='pantry-boy-services' element={<PantryBoyServices />} />
          <Route path='donate-us' element={<DonateUs />} />
          <Route path='why-us' element={<Whyus />} />
          <Route path='privacy-policy' element={<Privacy />} />
          <Route Path='register-helper' element={<Helper />} />
          <Route path='terms-and-conditions' element={<Terms />} />
          <Route path='book-now' element={<Book />} />
          <Route path='Booknew' element={<Booknew />} />
          <Route path='contact-us' element={<ContactUs />} />
          <Route path='blog/high-quality-cleaning-services-in-noida' element={<Blog1 />} />
          <Route path='blog/professional-cleaning-services-in-noida' element={<Blog2 />} />
          <Route path='blog/commercial-cleaning-services-in-noida' element={<Blog3 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;