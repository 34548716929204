import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Contactus from "../components/Contactus";
import Floatingcall from "../components/floatingbutton/Floatingcall";
import { Helmet } from "react-helmet";

function PantryBoyServices(){

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}

    return(
        <Fragment>
          <Helmet>
            <title>Pantry Boy Services | Pantry Boy Services in Noida </title>
            <meta name="tttle" content="Pantry Boy Services | Pantry Boy Services in Noida" />
            <meta name="description" content="Prioritize safety with our comprehensive sanitization services. We offer expert cleaning and sanitization services in Noida to ensure your spaces are germ-free and hygienic. Contact us today for a cleaner and healthier environment." />
            <meta name="keywords" content="Cleaning Services, Best Cleaning Services in Noida, Book Home Cleaning services Online in Noida, Cleaning and Sanitization Services in Noida, sanitization services near me, sanitization near me, deep cleaning services, Book Home Cleaning services Online in Noida, Cleaning and Sanitization Services, Sanitization Services in Noida" />
          </Helmet>
            
            <Floatingcall />
         
            <section id="about" class="about">
            <div class="container" data-aos="fade-up">

            <div class="section-header">
            <h2>Pantry Boy Services</h2>
            <p>Ensuring Smooth Operations & Impeccable Service with Helper Ji</p>
             </div>

           <div class="row gy-4">
           <div class="col-lg-6">
            <img src="/assets/img/Pantry-Boy-Services-1.jpg" class="img-fluid rounded-4 mb-4" alt="" />
            <div class="text-center"><Link to='/contact-us' className="booknow" onClick={scrollToTop}>Book Now</Link></div>
            </div>
          
          <div class="col-lg-6">
            <div class="content ps-0 ps-lg-5">
              <p>
              At HelperJi, we understand that a well-managed pantry is essential for the smooth functioning of any organization. Our Pantry Boys Services are designed to ensure that your workplace runs efficiently, with refreshments and essential supplies always available, and a clean, organized environment maintained at all times.
              </p>
              <p className="fs-5 fw-bold">What We Offer:</p>
              <p><strong>Refreshment Management:</strong> Our pantry boys are skilled in managing a wide range of refreshments, including beverages, snacks, and more. </p>
              <p><strong>Inventory Control:</strong> Keeping track of pantry supplies can be challenging. Our team diligently monitors inventory levels, ensuring timely restocking of essentials like tea, coffee, sugar, milk, and other items. </p>
              <p><strong>Hygiene & Cleanliness:</strong> Maintaining a clean and sanitary pantry is crucial for the health and well-being of everyone in your organization.</p>
              <p><strong>Guest Hospitality:</strong> Our pantry boys are also trained to provide courteous and professional service to your guests. </p>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section id="services" class="services sections-bg">
          <div class="container" data-aos="fade-up">
    
            <div class="row gy-4">
              
              <div class="col-lg-6">
                <div class="content ps-0 ps-lg-5">
                  <h2>Why Choose HelperJi ?</h2>
                  <p><strong>Trained Professionals:</strong> Our pantry boys are thoroughly trained and experienced in delivering high-quality service.</p>
                  <p><strong>Reliability:</strong> We pride ourselves on punctuality and dependability, ensuring your pantry is always well-managed.</p>
                  <p><strong>Attention to Detail:</strong> Our team is detail-oriented, ensuring that every aspect of pantry management is handled with care.</p>
                  <p><strong>Flexible Solutions:</strong> We offer tailored solutions that can be adjusted to suit the changing needs of your organization.</p>
                  <p className="fs-5 fw-bold">Get in Touch</p>
                  <p>If you're looking for reliable and professional pantry boys services, HelperJi is here to help. Contact us today to discuss your needs and learn more about how we can support your organization with our expert pantry management services.</p>
                  <div class="text-center"><Link to='/contact-us' className="booknow" onClick={scrollToTop}>Book Now</Link></div>
                </div>
              </div>
              <div class="col-lg-6">
                <img src="/assets/img/Pantry-Boy-Services-2.jpg" class="img-fluid rounded-4 mb-4" alt="" />
              </div>
            </div>
    
          </div>
        </section>

       <Contactus />
        </Fragment>
    )
}

export default PantryBoyServices;