import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Footer(){
    return(
        <Fragment>
            <footer id="footer" className="footer">
<div className="container">
  <div className="row gy-4">
    <div className="col-lg-6 col-md-12 footer-info">
      <Link to='/' className="logo d-flex align-items-center"><img src="/assets/img/footer-logo.png" alt="logo" /> </Link>
      <p className="text-justify">Helper Ji offers a complete range of cleaning services for your homes and offices. 
         Our cleaning services are available in all Delhi NCR region. 
         We'll deep clean your home, office, sofa, bathroom etc to make them look good as new.
         HelperJi make your life easy so, you can leave the cleaning worries to us.</p>
      <div className="social-links d-flex mt-4">
      <a href="https://twitter.com/helperji_" className="twitter" target="_blank" rel="noreferrer noopener"><i className="bi bi-twitter"></i></a>
        <a href="https://www.facebook.com/helperjiofficial" className="facebook" target="_blank" rel="noreferrer noopener"><i className="bi bi-facebook"></i></a>
        <a href="https://www.instagram.com/helperjiofficial/" className="instagram" target="_blank" rel="noreferrer noopener"><i className="bi bi-instagram"></i></a>
        <a href="https://in.pinterest.com/helperjiofficial/" className="linkedin" target="_blank" rel="noreferrer noopener"><i className="bi bi-pinterest"></i></a>
      </div>
    </div>
    <div className="col-lg-3 col-6 footer-links">
      <h4>Useful Links</h4>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="register-helper">Register Helper</Link> </li>
        <li><Link to="donate-us">Donate Us</Link></li>
        <li><Link to="why-us">Why Choose Us</Link></li>
        <li><Link to="privacy-policy">Privacy policy</Link></li>
        <li><Link to="terms-and-conditions">Terms & Conditions</Link></li>
      </ul>
    </div>
    <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
      <h4>Contact Us</h4>
      <p>
        H-160, BSI Business Park <br />
        Sector - 63, Noida<br />
        UP - 201301 <br /><br />
        <strong>Phone:</strong> 7800 7800 95<br />
        <strong>Email:</strong> contact@helperji.in<br />
      </p>
    </div>
  </div>
</div>
<div className="container mt-4">
  <div className="copyright">
    2024 &copy; Copyright <strong><span>Helperji Manpower Services Hub Pvt. Ltd.</span></strong>. All Rights Reserved.
  </div>
</div>
</footer>
        </Fragment>
    )
}
export default Footer;